<template>
  <v-app id="inspire">
    <Header v-model="expandOnHover"></Header>
    <Sidebar
     v-if="userRole='Admin' && userRole != 'SubAdmin'"
      :expand-on-hover.sync="expandOnHover"
    ></Sidebar>
    <!-- <SubAdminSidebar
      v-if="(userRole = 'SubAdmin' && userRole != 'Admin')"
      :expand-on-hover.sync="expandOnHover"
    ></SubAdminSidebar> -->
    <v-main class="">
      <router-view :key="$route.fullPath" />
    </v-main>
    <Footer></Footer>
  </v-app>
</template>
<script>
import axios from "axios";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
// import SubAdminSidebar from "./sidebar/subAdminSidebar";
import Footer from "./footer/Footer";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Layout",
  components: {
    Header,
    Sidebar,
    // SubAdminSidebar,
    Footer,
  },

  props: {
    source: String,
  },
  data: () => ({
    expandOnHover: false,
    user: [],
    username: localStorage.getItem("userName"),
    userRole: localStorage.getItem("userRole"),
  }),
  computed: {
    ...mapState(["Customizer_drawer"]),
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapMutations({
      setCustomizerDrawer: "SET_CUSTOMIZER_DRAWER",
    }),
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/profile",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.user = response.data.data;
            // this.userStatus = this.user.status;
            console.log(this.user);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
