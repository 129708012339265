<template>
  <div>
    <ServerError v-if="ServerError" />
    <!-- <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    /> -->

    <v-app-bar
      app
      clipped-left
      clipped-right
      color="white"
      elevate-on-scroll
      fixed
    >   <v-app-bar-nav-icon
        class="hidden-sm-and-down"
        @click="
          $vuetify.breakpoint.smAndDown
            ? setSidebarDrawer(!Sidebar_drawer)
            : $emit('input', !value)
        "
      />

      <v-app-bar-nav-icon
        class="d-block d-md-none"
        @click="
          $vuetify.breakpoint.smAndDown
            ? setSidebarDrawer(!Sidebar_drawer)
            : $emit('input', !value)
        "
      />
      <v-toolbar-title class="align-center d-flex">
        <v-img
          :src="require('../../assets/logo/Styloop_Logo1.png')"
          width="139"
        ></v-img>
      </v-toolbar-title>

      <v-spacer />

      <!---right part -->
      <!-- <v-menu
        offset-y
        origin="center center"
        class="elelvation-1"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn class="mx-5" icon text slot="activator" v-on="on">
            <v-badge color="red" overlap>
              <span slot="badge">3</span>
              <v-icon medium>mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>
      </v-menu> -->
      <span v-if="imga">
        <v-menu bottom offset-y transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-avatar style="cursor: pointer" size="40" v-on="on">
              <v-img :src="baseURL + imga" />
            </v-avatar>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, i) in userprofile"
              :key="i"
              :to="item.route"
            >
              <v-list-item-title> {{ item.title }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-list-item-title>Logout </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
      <span v-else>
        <v-icon>mdi-account</v-icon>
      </span>
      
    </v-app-bar>  
    
    <div><hr/></div>
   
  </div>
</template>
<script>
// Utilities
import store from "../../store";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Header",

  components: {},

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    proimg: "",
    // appLoading: false,
    ServerError: false,
    userprofile: [
      { title: "My Profile", route: "/settingsAdmin/profile", func: "" },
      { title: "Settings", route: "/settings/settingsList", func: "" },
      // { title: "Inbox", route: "", func: "" },
      // { title: "Account Setting", route: "" },
    ],
    href() {
      return undefined;
    },
  }),

  computed: {
    ...mapState(["Sidebar_drawer"]),

    imga() {
      return store.state.profilePic;
    },
  },
  mounted() {},
  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER",
    }),

    logout() {
      store.commit("logoutUser");
      localStorage.removeItem("token");
      localStorage.removeItem("isLoggedIn");
      this.$router.push("/auth/login");
    },
  },
};
</script>