<template>
  <v-navigation-drawer
    v-model="Sidebar_drawer"
    :dark="SidebarColor !== 'white'"
    :color="SidebarColor"
    mobile-breakpoint="960"
    clipped
    :right="$vuetify.rtl"
    mini-variant-width="70"
    :expand-on-hover="expandOnHover"
    app
    id="main-sidebar"
  >
    <v-list dense nav>
      <!---USer Area -->
      <v-list-item two-line class="px-0">
        <v-list-item-avatar>
          <v-img :src="baseURL + imga" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ user.username }}</v-list-item-title>
          <v-list-item-subtitle class="caption">{{
            user.type
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <!---USer Area -->
      <!---Sidebar Items -->
      <v-flex v-if="user.type == 'Admin' || user.type == 'SubAdmin'">
        <v-list-item
          v-for="item in dash"
          :key="item.title"
          :to="item.to"
          :class="item.class"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-flex>
      <v-flex v-if="user.type == 'Admin'">
        <v-list-item
          v-for="item in admin"
          :key="item.title"
          :to="item.to"
          :class="item.class"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-flex>
      <v-flex v-if="user.type == 'Admin' || user.type == 'SubAdmin'">
        <v-list-group
          :value="false"
          no-action
          prepend-icon="mdi-account-switch"
          :active-class="`white--text`"
        >
          <template v-slot:activator>
            <v-list-item-title>Sellers</v-list-item-title>
          </template>
          <v-list-item
            v-for="item in seller"
            :key="item.title"
            :to="item.to"
            :active-class="`red white--text`"
            link
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
          :value="false"
          no-action
          prepend-icon="mdi-account-switch"
          :active-class="`white--text`"
        >
          <template v-slot:activator>
            <v-list-item-title>Style Partners</v-list-item-title>
          </template>
          <v-list-item
            v-for="item in stylePartners"
            :key="item.title"
            :to="item.to"
            :active-class="`red white--text`"
            link
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-flex xs12 v-if="user.type == 'Admin'">
        <v-list-group
          :value="false"
          no-action
          prepend-icon="mdi-account-switch"
          :active-class="`white--text`"
          class="cl"
        >
          <template v-slot:activator>
            <v-list-item-title>Affiliators</v-list-item-title>
          </template>
          <v-list-item
            v-for="item in affiliator"
            :key="item.title"
            :to="item.to"
            :active-class="`red white--text`"
            link
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        </v-flex>
        <v-list-group
          :value="false"
          no-action
          prepend-icon="mdi-application"
          :active-class="`white--text`"
          class="cl"
        >
          <template v-slot:activator>
            <v-list-item-title>Appointment</v-list-item-title>
          </template>
          <v-list-item
            v-for="item in appointment"
            :key="item.title"
            :to="item.to"
            :active-class="`red white--text`"
            link
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
          :value="false"
          no-action
          prepend-icon="mdi-account-cog"
          :active-class="`white--text`"
        >
          <template v-slot:activator>
            <v-list-item-title>Landing Page</v-list-item-title>
          </template>
          <v-list-item
            v-for="item in landing"
            :key="item.title"
            :to="item.to"
            :active-class="`red white--text`"
            link
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-flex>
      <v-flex v-if="user.type == 'Admin' || user.type == 'SubAdmin'">
        <v-list-group
          :value="false"
          no-action
          prepend-icon="mdi-gift"
          :active-class="`white--text`"
        >
          <template v-slot:activator>
            <v-list-item-title>Products</v-list-item-title>
          </template>
          <v-list-item
            v-for="item in products"
            :key="item.title"
            :to="item.to"
            :active-class="`red white--text`"
            link
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
          :value="false"
          no-action
          prepend-icon="mdi-offer"
          :active-class="`white--text`"
        >
          <template v-slot:activator>
            <v-list-item-title>Offers & Deals</v-list-item-title>
          </template>
          <v-list-item
            v-for="item in offerDeals"
            :key="item.title"
            :to="item.to"
            :active-class="`red white--text`"
            link
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item
          to="/brandList"
          :active-class="`red white--text`"
          link
          class="cl"
        >
          <v-list-item-icon>
            <v-icon>mdi-tag</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Brand</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="user.type == 'Admin'"
          to="/hsnList"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-content-save</v-icon>
          </v-list-item-icon>
          <v-list-item-title>HSN Code</v-list-item-title>
        </v-list-item>
        <v-list-group
          :value="false"
          no-action
          prepend-icon="mdi-view-list"
          :active-class="`white--text`"
        >
          <template v-slot:activator>
            <v-list-item-title>Category</v-list-item-title>
          </template>
          <v-list-item
            v-for="item in category"
            :key="item.title"
            :to="item.to"
            :active-class="`red white--text`"
            link
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
          :value="false"
          no-action
          prepend-icon="mdi-looks"
          :active-class="`white--text`"
        >
          <template v-slot:activator>
            <v-list-item-title>Look Book</v-list-item-title>
          </template>
          <v-list-item
            v-for="item in lookBook"
            :key="item.title"
            :to="item.to"
            :active-class="`red white--text`"
            link
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
          :value="false"
          no-action
          prepend-icon="mdi-camera-plus"
          :active-class="`white--text`"
        >
          <template v-slot:activator>
            <v-list-item-title>Story</v-list-item-title>
          </template>
          <v-list-item
            v-for="item in story"
            :key="item.title"
            :to="item.to"
            :active-class="`red white--text`"
            link
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-flex xs12 v-if="user.type == 'Admin'">
        <v-list-group
          :value="false"
          no-action
          prepend-icon="mdi-cart"
          :active-class="`white--text`"
          class="cl"
        >
          <template v-slot:activator>
            <v-list-item-title>Report</v-list-item-title>
          </template>
          <v-list-item
            v-for="item in report"
            :key="item.title"
            :to="item.to"
            :active-class="`red white--text`"
            link
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-flex>
        <v-list-item
          v-for="item in common"
          :key="item.title"
          :to="item.to"
          :class="item.class"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          :value="false"
          no-action
          prepend-icon="mdi-phone-log"
          :active-class="`white--text`"
        >
          <template v-slot:activator>
            <v-list-item-title>Greetings</v-list-item-title>
          </template>
          <v-list-item
            v-for="item in invitation"
            :key="item.title"
            :to="item.to"
            :active-class="`red white--text`"
            link
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-flex>
      <!---Sidebar Items -->

      <v-list-group
        :value="false"
        no-action
        prepend-icon="mdi-home"
        :active-class="`white--text`"
      >
        <template v-slot:activator>
          <v-list-item-title>Home Display</v-list-item-title>
        </template>
        <v-list-item
          v-for="item in admins"
          :key="item.title"
          :to="item.to"
          :active-class="`red white--text`"
          link
        >
          <!-- <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon> -->
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group
        :value="false"
        no-action
        prepend-icon="mdi-information"
        :active-class="`white--text`"
      >
        <template v-slot:activator>
          <v-list-item-title>Static</v-list-item-title>
        </template>
        <v-list-item
          to="/Footer/footerHeading"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-title>Footer Heading</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item to="/promoCode" :active-class="`red white--text`" link>
          <v-list-item-title>Promo Code</v-list-item-title>
        </v-list-item> -->
        <v-list-item
          to="/staticFooter/Our Story"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-title>{{ title.ourStory }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/staticFooter/Refer A Friend"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-title>{{ title.referAFreind }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/newBlog" :active-class="`red white--text`" link>
          <v-list-item-title>{{ title.termsOfUse }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/staticFooter/FAQ"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-title>{{ title.FAQ }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/staticFooter/Careers"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-title>{{ title.Careers }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/staticFooter/Exchange & Return"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-title>{{ title.ExchangeAndReturn }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/staticFooter/Sell on Styloop"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-title>{{ title.sellOnStyloop }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/staticFooter/Collaborate with us"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-title>{{ title.CollaborateWithUs }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/staticFooter/Invest With US"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-title>{{ title.investWithUS }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/staticComponent/Marquee"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-title>Marquee</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/staticComponent/Styloop"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-title>Styloop</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/staticComponent/Styloop Description"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-title>Styloop Description</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/staticComponent/Company"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-title>Company</v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/staticStylook/stylook"
          :active-class="`red white--text`"
          link
        >
          <v-list-item-title>Stylook</v-list-item-title>
        </v-list-item>
      </v-list-group>
       <v-list-group
        :value="false"
        no-action
        prepend-icon="mdi-lightning-bolt-circle"
        :active-class="`white--text`"
      >
        <template v-slot:activator>
          <v-list-item-title>Coins & Referal</v-list-item-title>
        </template>
        <v-list-item
          v-for="item in setting"
          :key="item.title"
          :to="item.to"
          :active-class="`red white--text`"
          link
        >
          <!-- <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon> -->
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import store from "../../store";
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "Sidebar",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    user: [],
    title: [],
    landing: [
      {
        title: "Landing Page",
        to: "/LandingPage/landingPage",
        class: "",
      },
      {
        title: "Landing Page Queries",
        to: "/LandingPage/queries",
        class: "",
      },
    ],
    seller: [
      {
        title: "Sellers",
        // icon: "mdi-account-group",
        to: "/sellers/seller",
      },
      {
        title: "Pending Sellers",
        to: "/pendingSellers",
      },
      {
        title: "Removed Sellers",
        to: "/removeSellers",
      },
      //{
        //title: "Pincodes",
       // to: "/pincodes",
      //},
    ],
    stylePartners: [
      {
        title: "Style Partners",
        // icon: "mdi-account-group",
        to: "/stylePartners",
      },
      {
        title: "Pending Style Partners",
        to: "/pendingStylePartners",
      },
      {
        title: "Removed Style Partners",
        to: "/removedStylePartners",
      },
      {
        title: "Influencer Tags",
        icon: "mdi-tag-multiple",
        to: "/influencerTags",
      },
      {
        title: "Keywords",
        icon: "mdi-tag-multiple",
        to: "/keywords",
      },
      {
        title: "Pending Appointment",
        to: "/pendingAppointment",
      },
      {
        title: "Active Appointment",
        to: "/activeAppointment",
      },
      //{
        //title: "Pincodes",
       // to: "/pincodes",
      //},
    ],
    appointment: [
      
      {
        title: "Pending Appointment",
        to: "/pendingAppointment",
      },
      {
        title: "Active Appointment",
        to: "/activeAppointment",
      },
    ],
    affiliator: [
      {
        title: "Affiliators",
        // icon: "mdi-account-group",
        to: "/affiliator",
      },
      {
        title: "Pending Affiliators",
        to: "/pendingAffiliator",
      },
      {
        title: "Pending Theme",
        to: "/pendingTheme",
      },
      {
      title:"Affiliation Commission",
      to:"/commission"
    },
    {
      title:"Affiliation Report User",
      to:"/affiliationReport"
    },
    {
      title:"Affiliation Report Style Partners",
      to:"/affiliationReportSP"
    }
    ],
    products: [
      {
        title: "Active Products",
        icon: "mdi-gift",
        to: "/Products/productView",
      },
      {
        title: "Pending Products",
        icon: "mdi-dropbox",
        to: "/pendingProducts",
      },
      {
        title: "Offer Products",
        icon: "mdi-offer",
        to: "/offer",
      },
      {
        title: "Product Tags",
        icon: "mdi-dropbox",
        to: "/ProductTags",
      },
    ],
    admins: [
      { title: "Slider", to: "/home/sliderList" },
      { title: "Trending Slider", to: "/home/trendingSlider" },
      { title: "Exclusive", to: "/home/exclusiveList" },
      { title: "Trending", to: "/home/trendingList" },
      { title: "Discount Vouchers", to: "/home/blog" },
      { title: "House of Beauty", to: "/home/houseofBeauty" },
      {
        title: "Main Collection",
        to: "/home/mainCollection",
      },
      { title: "Collection", to: "/home/collectionList" },
      { title: "Login Background", to: "/home/loginBackground" },
      { title: "Feedback Content", to: "/home/lookingFor" },
    ],
    stat: [
      {
        title: "Marquee",
        to: "/staticComponent/Marquee",
      },
      {
        title: "Footer Heading",
        to: "/staticComponent/Footer Heading",
      },
      {
        title: "Styloop Description",
        to: "/staticComponent/Styloop Description",
      },
      {
        title: "Company",
        to: "/staticComponent/Company",
      },
    ],
    setting:[
      {
      title:"Super Coins",
      to:"/superCoins"
    },
    {
      title:"Set Coin Range",
      to:"/coinRange"
    },
    {
      title:"Set Coin Range Offline",
      to:"/coinRangeOffline"
    },
    {
      title:"Pending Bills",
      to:"/pendingBills"
    },
    {
      title:"Active Bills",
      to:"/activeBills"
    },
    {
      title:"Referal Limit",
      to:"/referalRange"
    },
    ],
    dash: [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        to: "/dashboard/basic-dashboard",
      },
    ],
    admin: [
      {
        title: "Add Reviews",
        icon: "mdi-star-plus",
        to: "/rating/ratingList",
      },
      {
        title: "User Reviews",
        icon: "mdi-star",
        to: "/UserReview/userReview",
      },
      {
        title: "Subscribers List",
        icon: "mdi-youtube-subscription",
        to: "/Subscribe/subscribe",
      },
      {
        title: "Guest Feedback",
        icon: "mdi-monitor-eye",
        to: "/Guest/lookingFor",
        // class: "cl",
      },
      {
        title: "Business Query",
        icon: "mdi-account-cog",
        to: "/Business/businessQuery",
        class: "",
      },
      //   {
      //   title: "Landing Page Queries",
      //   icon: "mdi-account-cog",
      //   to: "/LandingPage/queries",
      //   class: "",
      // },
      //  {
      //   title: "Dynamic Image Links",
      //   icon: "mdi-account-cog",
      //   to: "/dynamicLinks",
      //   class: "",
      // },
      {
        title: "Users",
        icon: "mdi-account-group",
        to: "/users/user",
        class: "space",
      },
      {
        title: "Admins",
        icon: "mdi-account-supervisor",
        to: "/admins/admin",
        // class:'cl',
      },
      {
        title: "Omni Store",
        icon: "mdi-account-supervisor",
        to: "/ominiStore",
      },
    ],
    offerDeals: [
    {
        title: "Pending Offers",
        icon: "mdi-offer",
        to: "/pendingOffer",
      },
      {
        title: "Pending Deals",
        icon: "mdi-share-circle",
        to: "/Deal/deal",
      },
      {
        title: "Approved Deals",
        icon: "mdi-handshake",
        to: "/Deal/approvedDeals",
      },
    ],
    lookBook: [
    {
        title: "Shop By Look-Category",
        icon: "mdi-looks",
        to: "/shopByLookCategory",
      },
      {
        title: "Shop By Look-Theme",
        icon: "mdi-looks",
        to: "/shopByLookTheme",
      },
      {
        title: "Theme Enquiry",
        icon: "mdi-looks",
        to: "/themeEnquiry",
        class: "cl",
      },
      {
        title: "Discover Color Combination",
        icon: "mdi-palette",
        to: "/colorCombination",
      },
      {
        title: "Discover Body Type",
        icon: "mdi-human-male-female",
        to: "/bodyType",
      },
      {
        title: "Discover Enquiry List",
        icon: "mdi-information",
        to: "/discoverList",
        class: "cl",
      },
    ],
    category: [
    {
        title: "Category",
        icon: "mdi-view-list",
        to: "/category/categories",
        class: "space",
      },
      {
        title: "Styl-Category",
        icon: "mdi-format-list-text",
        to: "/stylCategory/stylCategories",
      },
      {
        title: "Sub-category",
        icon: "mdi-format-list-bulleted-square",
        to: "/subCategory",
        // class:"cl"
      },
      {
        title: "Blog-Category",
        icon: "mdi-format-list-text",
        to: "/blogCategories",
        class: "cl",
      },
    ],
    story: [
    {
        title: "Story Category",
        icon: "mdi-camera-plus",
        to: "/storyCategory",
      },
      {
        title: "Story",
        icon: "mdi-camera-image",
        to: "/stories",
      },
      {
        title: "Story Head",
        icon: "mdi-camera-flip",
        to: "/storyHead",
      },
      {
        title: "Common Image",
        icon: "mdi-camera-flip",
        to: "/commonImage",
      },
      {
        title: "Story Enquiry",
        icon: "mdi-camera-flip",
        to: "/storyEnquiry",
      },
      {
        title: "Website Requests",
        icon: "mdi-camera-flip",
        to: "/websiteRequest",
      },
    ],
    invitation: [
    {
        title: "Greeting Category",
        icon: "mdi-phone-log",
        to: "/invitationCategory",
      },
      {
        title: "Invitation List",
        icon: "mdi-phone",
        to: "/invitation",
      },
       {
        title: "Greeting List",
        icon: "mdi-phone",
        to: "/invitationFestival",
      },
      {
        title: "Refer Friends List",
        icon: "mdi-phone-in-talk",
        to: "/referFriendsList",
      },
    ],
    report: [
    {
        title: "Purchase",
        icon: "mdi-cart",
        to: "/purchases/purchaseList",
      },
      {
        title: "Payment Reports",
        icon: "mdi-cart",
        to: "/paymentReport",
      },
    ],
    common: [
      // {
      //   title: "Size Chart",
      //   icon: "mdi-view-list",
      //   to: "/sizeChart",
      // },
      
      {
        title: "Promo Codes",
        icon: "mdi-barcode",
        to: "/newPromocode",
      },
       {
        title: "Vouchers",
        icon: "mdi-ticket-percent",
        to: "/voucherList",
      },
      // {
      //   title: "Tags",
      //   icon: "mdi-tag-multiple",
      //   to: "/tag",
      // },
      // {
      //   title: "HSN Codes",
      //   icon: "mdi-content-save",
      //   to: "/hsnList",
      // },
      // {
      //   title: "Footer Heading",
      //   icon: "mdi-page-layout-header-footer",
      //   to: "/Footer/footerHeading",
      // },
      
     
      // {
      //   title: "Story Quotes",
      //   icon: "mdi-tray-full",
      //   to: "/storyQuotesList",
      //   class: "cl",
      // },
      {
        title: "Trial Room",
        icon: "mdi-store",
        to: "/trialRoom",
      },
      {
        title: "Wishlist Purpose",
        icon: "mdi-bookmark-outline",
        to: "/wishlistPurpose",
        class:"cl"
      },
      // {
      //   title: "Wishlist Purpose User Respond",
      //   icon: "mdi-bookmark-multiple",
      //   to: "/wishlistPurposeUserRespond",
      // },
      // {
      //   title: "Wishlist Personalize Search",
      //   icon: "mdi-book-search",
      //   to: "/wishlistSearch",
      //   class: "cl",
      // },
    ],
    // subadmin: [
    //   {
    //     title: "Products",
    //     icon: "mdi-gift",
    //     to: "/Products/productView",
    //   },
    //   {
    //     title: "Pending Product",
    //     icon: "mdi-dropbox",
    //     to: "/pendingProducts",
    //   },
    //   {
    //     title: "Pending Offer",
    //     icon: "mdi-offer",
    //     to: "/pendingOffer/offer",
    //   },
    //   {
    //     title: "Pending Deals",
    //     icon: "mdi-share-circle",
    //     to: "/Deal/deal",
    //   },
    //   {
    //     title: "Approved Deals",
    //     icon: "mdi-handshake",
    //     to: "/Deal/approvedDeals",
    //   },
    //   {
    //     title: "Purchase",
    //     icon: "mdi-cart",
    //     to: "/purchases/purchaseList",
    //   },
    //   // {
    //   //   title: "Footer Heading",
    //   //   icon: "mdi-page-layout-header-footer",
    //   //   to: "/Footer/footerHeading",
    //   // },
    //   {
    //     title: "Category",
    //     icon: "mdi-view-list",
    //     to: "/category/categories",
    //     class: "space",
    //   },
    //   {
    //     title: "Styl-Category",
    //     icon: "mdi-format-list-text",
    //     to: "/stylCategory/stylCategories",
    //   },
    //   {
    //     title: "Sub-category",
    //     icon: "mdi-format-list-bulleted-square",
    //     to: "/subCategory",
    //     // class:"cl"
    //   },
    // ],
   
  }),
  computed: {
    imga() {
      return store.state.profilePic;
    },
    ...mapState(["SidebarColor", "SidebarBg"]),
    Sidebar_drawer: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      },
    },
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },
  mounted() {
    this.getData();
    this.getList();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/profile",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.user = response.data.data;
            // this.userStatus = this.user.status;
            console.log(this.user);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getList() {
      this.appLoading = true;
      axios({
        url: "/home/getfootertext",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.title = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  .v-navigation-drawer__border {
    display: none;
  }
  .v-list {
    padding: 8px 15px;
  }
  .v-list-item {
    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
  }
}
.cl {
  padding-bottom: 15px !important;
}
.space {
  padding-top: 15px !important;
}
</style>
